import 'bootstrap' // used for eg. collapse of topnav
import '@lottiefiles/lottie-player'
import '@lottiefiles/lottie-interactivity'

function hexToRGB (hex: string, alpha: number): string {
  let r = parseInt(hex.slice(1, 3), 16)
  let g = parseInt(hex.slice(3, 5), 16)
  let b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}

const resolveColor = (color: string, alpha: number):string => {
  // Grab the hex value from the CSS root vars
  let colorHex = window.getComputedStyle(document.body).getPropertyValue(color).trim()

  if (alpha) {
    return hexToRGB(colorHex, alpha)
  } else {
    return colorHex
  }''
}

/******************
  Upload component
 ******************/
function fileInputsAddEventListener() {
  Array.prototype.forEach.call(
    document.getElementsByClassName("mf-file-input-inner"), 
    elt => elt.setAttribute("mf-file-input-content", "Upload file")
  );
  
  Array.prototype.forEach.call(
    document.getElementsByClassName("mf-file-input-inner"), 
    elt => elt.firstElementChild.addEventListener("change", handleFileUploaded)
  );
  Array.prototype.forEach.call(
    document.getElementsByClassName("mf-file-input-clear"), 
    elt => elt.addEventListener("click", handleClearFile)
  );
}

document.addEventListener("DOMContentLoaded",  fileInputsAddEventListener);
document.addEventListener("htmx:afterOnLoad", fileInputsAddEventListener);

// TODO: if filename is too long we need to short it down in display
// TODO: handle multiple files
function handleFileUploaded(event: Event) {
  if (!event) {
    return;
  }
  const target = event.target as HTMLElement;

  if (!target || !target.parentElement) {
    return;
  }
  if (!(target as HTMLInputElement).files || (target as HTMLInputElement).files.length === 0) {
    return;
  }
  target.parentElement.setAttribute("mf-file-input-content", target.files[0].name);
  target.parentElement.lastElementChild.style.visibility = "visible";
}

function handleClearFile(event: Event) {
  var mfFileInput = event.target.parentElement
  mfFileInput.setAttribute("mf-file-input-content", "Upload file");
  mfFileInput.firstElementChild.value = null;
  event.target.style.visibility = "hidden";
}

/**********
  Tooltips
 **********/
function loadTooltips() {
    document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((tooltip) => {
        new bootstrap.Tooltip(tooltip);
    });
}

document.addEventListener("DOMContentLoaded", loadTooltips);
document.addEventListener("htmx:afterOnLoad", loadTooltips);


export {
  fileInputsAddEventListener,
  loadTooltips,
  resolveColor
}